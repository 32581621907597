import React from "react";

export const ArrowUpIcon = ({ propsStyle, stroke = "#e5554f" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="9"
      viewBox="0 0 14 9"
      style={propsStyle}
    >
      <path
        fill="none"
        className="svgColor"
        strokeWidth="2"
        d="M13.333 7.667L7.037 1.37.741 7.667"
        stroke={stroke}
      ></path>
    </svg>
  );
};
