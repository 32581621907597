import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import styles from "./styles.module.css";
import Button from "../../components/Button";
import {getCookieValue} from "../../helpers/cookieParser";

const Step9 = () => {
  const { t } = useTranslation();
    const firstName = getCookieValue('first_name');
    const language = getCookieValue('url_lang');

  const navigate = useNavigate();

  const onSubmit = () => {
    navigate("/step10");
  };

  const videos = {
    en: 'https://www.youtube.com/embed/-qJIJ9VyVaY',
    ar: 'https://www.youtube.com/embed/44kqTVTdUcY',
  };

  return (
    <div className={styles.container}>
      <p className={styles.surveyHeading}>
        {t("You're almost there")}, {firstName}{" "}
          ✋
      </p>
      <p className={styles.surveyHeading}>
        {t("Lastly we need 3 photos to fully assess your smile.")}
      </p>
          <figure className={styles.videoContainer}>
            <iframe
              title="youtube-video"
              src={videos[language]}
              frameBorder="0"
              allowFullScreen=""
            />
          </figure>
      <Button title={t("take 3 photos")} propsStyles={{margin: '0 auto'}} onClick={onSubmit}/>
    </div>
  );
};

export default Step9;
