import {useCallback, useEffect, useState} from "react";
import {Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import Step1 from "../../steps/Step1";
import Step2 from "../../steps/Step2";
import Step3 from "../../steps/Step3";
import Step4 from "../../steps/Step4";
import Step5 from "../../steps/Step5";
import Step6 from "../../steps/Step6";
import Step7 from "../../steps/Step7";
import Step8 from "../../steps/Step8";
import Step9 from "../../steps/Step9";
import AdultOnly from "../../pages/AdultOnly";
import FormComplete from "../../pages/FormComplete";
import FrontPhotoStep from "../../steps/Step10/FrontPhotoStep";
import LowerJawPhotoStep from "../../steps/Step10/LowerPhotoStep";
import UpperJawPhotoStep from "../../steps/Step10/UpperPhotoStep";
import '../../translation/i18n';
import makeApiRequest from '../../helpers/makeApiRequest';
import Header from "../Header";
import Navigator from "../Navigator";
import {getCookieValue, setCookie} from "../../helpers/cookieParser";
import {navigationMap} from "../../steps/static";
import {LayoutOverlay} from "../LoadingOverlay";

const Router = () => {
    const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const phone_number = queryParams.get('p');
  const [lang, setLang] = useState('en');

  useEffect(() => {
      // if(!getCookieValue('url_country')) {
      //     navigate('/');
      //     return;
      // }

      const id = getCookieValue('id');

      setLoading(true);
      if(phone_number) {
          console.log('trigger');
          makeApiRequest(`customers/get/+${phone_number}/`)
              .then((response) => {
                  setLoading(false);
                  if (response.detail === 'Not found.') {
                      setCookie('first_name', '');
                      setCookie('phone_number', '');
                      navigate('/');
                  } else {
                      setCookie('id', response.id);
                      setCookie('status', response.status);
                      setCookie('phone_number', phone_number);
                      setCookie('url_country', response.country);
                      setCookie('url_lang', response.language);
                      setCookie('location', response.location);
                      setCookie('teeth_conditions', response.teeth_conditions);
                      setCookie('email', response.email);
                      setCookie('is_adult', response.is_adult?.toString());
                      setCookie('braces_before', response.braces_before?.toString());
                      setCookie('teeth_type', response.teeth_type?.toString());

                      setCookie('first_name', `${response.first_name}${response.last_name}`);

                      setCookie('lower_photo_url', response.lower_jaw_photo_url);
                      setCookie('upper_photo_url', response.upper_jaw_photo_url);
                      setCookie('front_photo_url', response.front_photo_url);
                      const route = navigationMap[response.status];
                      navigate(route);
                  }
              })
      } else {
          if (id) {
              makeApiRequest(`customers/${id}/`)
                  .then((response) => {
                      setLoading(false);

                      if (!response.error) {
                          if(response.status === 8) {
                              const frontPhotoURL = getCookieValue('front_photo_url');
                              const lowerPhotoURL = getCookieValue('lower_jaw_photo_url');
                              const upperPhotoURL = getCookieValue('upper_jaw_photo_url');

                              if(upperPhotoURL) {
                                  navigate('/step10-upper_jaw_photo');
                                  return;
                              }

                              if(lowerPhotoURL) {
                                  navigate('/step10-lower_jaw_photo');
                                  return;
                              }

                              if(location.pathname === '/step10' && frontPhotoURL) {
                                  navigate('/step10');
                                  return;
                              }

                              if(location.pathname === '/step10-lower_jaw_photo' && frontPhotoURL) {
                                  navigate('/step10-lower_jaw_photo');
                                  return;
                              }
                          }
                          setCookie('status', response.status);
                          const route = navigationMap[response.status];
                          navigate(route);
                      }
                  })
          } else {
              const isAdult = getCookieValue('is_adult');

              if(isAdult === 'false') {
                  navigate('/step3');
                  setLoading(false);
                  return;
              }

              if(location.pathname !== '/step2') {
                  navigate('/');
              }
              setLoading(false);
          }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLangHandler = useCallback((value) => {
      setLang(value);
  }, []);

  return (
      <>
          <Header setLang={setLangHandler} />
          <LayoutOverlay isActive={loading}>
              <div className="app-container">
                  <Routes>
                      <Route path="/" element={<Step1 />} />
                      <Route path="/step2" element={<Step2 />} />
                      <Route path="/step3" element={<Step3 />} />
                      <Route path="/step4" element={<Step4 />} />
                      <Route path="/step5" element={<Step5 />} />
                      <Route path="/step6" element={<Step6 />} />
                      <Route path="/step7" element={<Step7 />} />
                      <Route path="/step8" element={<Step8 />} />
                      <Route path="/step9" element={<Step9 />} />
                      <Route path="/step10" element={<FrontPhotoStep />} />
                      <Route path="/step10-lower_jaw_photo" element={<LowerJawPhotoStep />} />
                      <Route path="/step10-upper_jaw_photo" element={<UpperJawPhotoStep />} />
                      <Route path="/adult-only" element={<AdultOnly />} />
                      <Route path="/form-complete" element={<FormComplete />} />
                  </Routes>
                  {(location.pathname !== '/form-complete' && location.pathname !== '/adult-only') ? <Navigator lang={lang} /> : null}
              </div>
          </LayoutOverlay>
      </>
  );
}

export default Router;
