import React from "react";

export const CheckMarkIcon = ({propsStyle, stroke = "#fff", fill = "#fff"}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={propsStyle}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7698 4.20939C13.0684 4.49648 13.0777 4.97127 12.7906 5.26984L6.54055 11.7699C6.39617 11.92 6.19584 12.0034 5.98755 11.9999C5.77927 11.9965 5.5818 11.9066 5.44245 11.7517L3.19248 9.25171C2.91539 8.94383 2.94035 8.46961 3.24823 8.19252C3.55612 7.91543 4.03034 7.94039 4.30743 8.24828L6.01809 10.1491L11.7093 4.23018C11.9964 3.9316 12.4712 3.92229 12.7698 4.20939Z"
        fill={fill}
        stroke={stroke}
      ></path>
    </svg>
  );
};
