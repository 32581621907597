import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import styles from "./styles.module.css";
import Button from "../../components/Button";
import {getCookieValue, setCookie} from "../../helpers/cookieParser";

const Step1 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cookieValue = getCookieValue('first_name');
  const [firstName, setFirstName] = useState(cookieValue || '');
  
  useEffect(() => {
    setCookie('step', '1');
  }, []);

  const onSubmit = (event) => {
      event.preventDefault();
      setCookie('first_name', firstName);
      setCookie('step', '2');
    navigate("/step2");
  };

  return (
      <form className={styles.form} onSubmit={onSubmit}>
        <p className={styles.surveyHeading}>
          <Trans t={t}>Let's start with your Name</Trans>
        </p>
        <input
          className={styles.input}
          defaultValue={cookieValue}
          onChange={(event) => setFirstName(event.target.value)}
          placeholder={t("Please enter your response")}
        />
        <Button disabled={!firstName.length} />
      </form>
  );
};

export default Step1;

