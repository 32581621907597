import styles from './styles.module.css';

export function AcceptedFileItem({ url, name, size }) {
  return (
      <div className={styles.preview}>
        <div className={styles['preview-image-box']}>
          <img src={url} className={styles['preview-image']} alt="first step" />
          <span className={styles['preview-image-name']}>{name}</span>
        </div>
      <div className={styles['preview-size-box']}>
        <span className={styles['preview-size']}>{size ? bytesToSize(size) : ''}</span>
      </div>
    </div>
  );
}


function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes} ${sizes[i]})`
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}
