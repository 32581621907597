import {memo} from "react";
import { ArrowUpIcon } from "../../assets/ArrowUpIcon";
import { ArrowDownIcon } from "../../assets/ArrowDownIcon";

import styles from "./styles.module.css";
import {useLocation, useNavigate} from "react-router-dom";
import {getCookieValue, setCookie} from "../../helpers/cookieParser";

export const Navigator = memo(({lang}) => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const firstName = getCookieValue('first_name');
    const country = getCookieValue('url_country');
    const phone = getCookieValue('phone_number');
    const isAdult = getCookieValue('is_adult');
    const teethConditions = getCookieValue('teeth_conditions');
    const bracesBefore = getCookieValue('braces_before');
    const teethType = getCookieValue('teeth_type');
    const locationCookie = getCookieValue('location');
    const email = getCookieValue('email');
    const frontPhotoURL = getCookieValue('front_photo_url');
    const lowerPhotoURL = getCookieValue('low_photo_url');
    const upperPhotoURL = getCookieValue('upper_photo_url');
    
    const validate = {
        '/': firstName,
        step2: phone && country,
        step3: isAdult && isAdult !== 'false',
        step4: teethType,
        step5: bracesBefore,
        step6: teethConditions,
        step7: locationCookie,
        step8: email,
        step9: true,
        step10: frontPhotoURL,
        'step10-lower_jaw_photo': lowerPhotoURL,
        'step10-upper_jaw_photo': upperPhotoURL,
    }
    
    const backRouteNumber = () => {
        if(pathname === '/form-complete') {
            return 'step10-upper_jaw_photo';
        }
        
        if(pathname === '/step10') {
            return 'step9';
        }
        
        if(pathname === '/step10-lower_jaw_photo') {
            return 'step10';
        }

        if(pathname === '/step10-upper_jaw_photo') {
            return '/step10-lower_jaw_photo';
        }
        
        const num = Number(pathname.slice(-1));
        if(num === 2) {
            setCookie('step', '1');
            return '/';
        }


        setCookie('step', `${num - 1}`);
        return `step${num - 1}`;
    }

    const forwardRouteNumber = () => {
        if(pathname === '/step10') {
            return '/step10-lower_jaw_photo';
        }

        if(pathname === '/step10-lower_jaw_photo') {
            return '/step10-upper_jaw_photo';
        }

        if(pathname === '/step10-upper_jaw_photo') {
            return '/form-complete';
        }
        
        if(pathname === '/') {
            setCookie('step', '2')
            return '/step2';
        }
        const num = Number(pathname.slice(-1));
        setCookie('step', `${num + 1}`);
        return `step${num + 1}`;
    }
    
  return (
          <div className={lang === 'ar' ? styles.arabic_wrapper : styles.wrapper}>
      <button
        className={
            !validate[pathname === '/' ? pathname : pathname.slice(1)]
            ? `${styles.button} ${styles.buttonDisabled}`
            : `${styles.button}`
        }
        onClick={() => navigate(`${forwardRouteNumber()}`)}
        disabled={!validate[pathname === '/' ? pathname : pathname.slice(1)]}
      >
        <ArrowDownIcon stroke="#333" propsStyle={{ width: 12, height: 12 }} />
      </button>
      <button
        className={
            (pathname === '/' || pathname === '/step9')
            ? `${styles.button} ${styles.buttonDisabled}`
            : `${styles.button}`
        }
        onClick={() => navigate(`${backRouteNumber()}`)}
        disabled={pathname === '/' || pathname === '/step9'}
      >
        <ArrowUpIcon stroke="#333" propsStyle={{ width: 12, height: 12 }} />
      </button>
    </div>
  );
});

export default Navigator;
