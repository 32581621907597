import styles from "./styles.module.css";
import { ArrowIcon } from "../../assets/ArrowIcon";
import { useTranslation } from 'react-i18next';

const Button = ({ onClick, title = "next", propsStyles, disabled, icon }) => {
  const { t } = useTranslation();

  const className = disabled
    ? `${styles.submitButton} ${styles.submitButtonDisabled}`
    : styles.submitButton;

  return (
    <button
      className={className}
      style={propsStyles}
      type="submit"
      onClick={onClick}
      disabled={disabled}
    >
      {t(title)}
      {icon ? icon : <ArrowIcon className={styles.arrow} propsStyle={{ marginLeft: 5 }} />}
    </button>
  );
}

export default Button;
