import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Step10, {ePhotoKeys} from "./index";
import {AcceptedFileItem} from "./AcceptedFileItem";
import {getCookieValue, setCookie} from "../../helpers/cookieParser";
import Button from "../../components/Button";
import styles from "./styles.module.css";
import makeApiRequest from "../../helpers/makeApiRequest";
import {LayoutOverlay} from "../../components/LoadingOverlay";
import {setFileName} from "../../helpers/imageFormat";

const FrontPhotoStep = () => {
    const [file, setFile] = useState(null);
    const [responseError, setResponseError] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const frontPhotoURL = getCookieValue('front_photo_url');
    const frontPhotoSize = getCookieValue('front_photo_size');
    const frontPhotoName = getCookieValue('front_photo_name');
    const id = getCookieValue('id');

    useEffect(() => {
        if(file) {
            setResponseError('');
            setError('');
            setCookie('front_photo_name', file.name);
            setCookie('front_photo_size', file.size);
        }
    }, [file])

    const onSubmit = async () => {
        if(!file) {
            setError('Please, upload new photo');
            return;
        }
        setError('');
        setLoading(true);
        const formData = new FormData();
        formData.append(ePhotoKeys.front_photo, file || frontPhotoURL, frontPhotoName);

        const response = await makeApiRequest(
            `customers/${id}/upload_photo/`,
            "POST",
            formData,
            true
        );

        setLoading(false);
        if (response?.error !== undefined) {
            setResponseError(response.error.front_photo);
        } else {
            setCookie('front_photo_url', response.front_photo_url);
            setCookie('front_photo_name', file.name);
            setCookie('front_photo_size', file.size);
            navigate('/step10-lower_jaw_photo');
        }
    };

    return (
        <div className={styles.wrapper}>
            <Step10 imageSrc='https://static.surveysparrow.com/application/production/1628265273997__2399373f1494f888ecbad944aa923ac5e9e9f2556abdd58f60139fbd3a32__Untitled design 8.gif'
                    headerText='Take a photo of your closed bite.'
                    setFile={setFile}
            />
            {responseError && <div className={styles['preview-image-box']}>
                {responseError.map((error) => (
                    <span key={error} style={{ color: "rgb(229, 85, 79)" }}>
                        {error}
                     </span>
                ))}
            </div>}
            {error && <div className={styles['preview-image-box']}>
                    <span key={error} style={{ color: "rgb(229, 85, 79)" }}>
                        {error}
                     </span>
            </div>}
            <LayoutOverlay isActive={loading}>
            {(file || frontPhotoURL)  &&
                <AcceptedFileItem
                    url={file ? URL.createObjectURL(file) : frontPhotoURL}
                    name={file ? setFileName(file.name) : setFileName(frontPhotoName)}
                    size={file ? file.size : frontPhotoSize}
                />
            }
            <Button onClick={onSubmit} />
            </LayoutOverlay>
        </div>
    )
}

export default FrontPhotoStep;
