import {useState} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import styles from "./styles.module.css";
import Button from "../../components/Button";
import { InfoIcon } from "../../assets/InfoIcon";
import makeApiRequest from "../../helpers/makeApiRequest";
import { navigationMap } from '../static';
import {getCookieValue, setCookie} from "../../helpers/cookieParser";
import {LayoutOverlay} from "../../components/LoadingOverlay";

const iconStyles = {
  display: "block",
};

const Step8 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cookieValue = getCookieValue('email');
  const id = getCookieValue('id');
  const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(cookieValue || '');
    const [error, setError] = useState('');

  const onSubmit = async (event) => {
      event.preventDefault();
    setLoading(true);
    const response = await makeApiRequest(`customers/${id}/`, "PATCH", {email});
    setLoading(false);
    if (!response.error) {
      setCookie('email', email);
      setCookie('status', response.status);
      const route = navigationMap[response.status];
      navigate(route || "/step9");
    }
  };

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <p className={styles.surveyHeading}>
        <Trans t={t}>To which Email should we send the Doctor Consultation invite</Trans>
      </p>
      <LayoutOverlay isActive={loading}>
      <div className={styles.inputWrapper}>
        <input
          type="email"
          autoComplete="off"
          onChange={(event) => {
              if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(event.target.value)) {
                  setError(t("Please enter a valid email"));
              } else {
                  setError('');
              }
              
              if(!event.target.value.length) {
                  setError(t("Please enter an email"))
              }
              
              setEmail(event.target.value)
          }}
          className={
            !error.length
              ? styles.input
              : `${styles.input} ${styles.inputError}`
          }
          defaultValue={email}
          disabled={loading}
          placeholder={t("Please enter an email")}
        />
        {error && (
          <div className={styles.errorWrapper}>
            <InfoIcon style={iconStyles} />
            <p className={styles.errorText}>{error}</p>
          </div>
        )}
      </div>
      </LayoutOverlay>
      <Button disabled={loading || error.length || !email.length} />
    </form>
  );
};

export default Step8;
