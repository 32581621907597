import React, {useEffect} from "react";
import { BrowserRouter} from 'react-router-dom';
import "./App.css";
import Router from './components/Router';
import {I18nextProvider, initReactI18next} from "react-i18next";
import {getCookieValue, setCookie} from './helpers/cookieParser';
import i18n from "i18next";
import TagManager from 'react-gtm-module';
import {resources} from "./translation/i18n";

const App = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const url_country = queryParams.get('url_country');
  const url_lang = queryParams.get('url_lang');

  let country = getCookieValue('url_country') || url_country;
  let language = getCookieValue('url_lang') || url_lang;

  i18n
      .use(initReactI18next)
      .init({
        resources,
        lng: language || 'en',
        interpolation: {
          escapeValue: false
        },
      });
  
  // 0568824418
  // ?url_lang=en&url_country=ae
    // ?p=+971508999999
    
  useEffect(() => {
      TagManager.initialize({ gtmId: 'GTM-PHJQWDS' });
      if(country) {
        setCookie('url_country', country);
      }  

      if(language) {
        setCookie('url_lang', language);
      } else {
          setCookie('url_lang', 'en');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
