export const Static = {
  PHONE_PROVIDED: 1,
  AGE_PROVIDED: 2,
  TEETH_TYPE_PROVIDED: 3,
  BRACES_PROVIDED: 4,
  TEETH_CONDITION_PROVIDED: 5,
  LOCATION_PROVIDED: 6,
  EMAIL_PROVIDED: 8,
  PHOTO_UPLOADED: 9,
  APPROVED: 10,
  PHOTO_BAD_QUALITY: 15
};

export const navigationMap = {
  [`${Static.PHONE_PROVIDED}`]: "/step3",
  [`${Static.AGE_PROVIDED}`]: "/step4",
  [`${Static.TEETH_TYPE_PROVIDED}`]: "/step5",
  [`${Static.BRACES_PROVIDED}`]: "/step6",
  [`${Static.TEETH_CONDITION_PROVIDED}`]: "/step7",
  [`${Static.LOCATION_PROVIDED}`]: "/step8",
  [`${Static.EMAIL_PROVIDED}`]: "/step9",
  [`${Static.PHOTO_BAD_QUALITY}`]: "/step9",
  [`${Static.APPROVED}`]: "/form-complete",
  [`${Static.PHOTO_UPLOADED}`]: "/form-complete",
};

export const teethTypes = [
  {key: 0, value: 'Crowded'},
  {key: 1, value: 'Spaced'},
  {key: 2, value: 'Crooked'},
  {key: 3, value: 'Sticking out'},
]

export const teethConditions = [
  'I have unfilled cavities',
  'I have gum disease',
  'I have osteoporosis',
  'I have fixed retainers',
    'None of the above'
];
