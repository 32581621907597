import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';

import styles from "./styles.module.css";
import crooked from "../../assets/crooked.png";
import crowded from "../../assets/crowded.png";
import spaced from "../../assets/spaced.png";
import stickingOut from "../../assets/sticking_out.png";
import { CheckMarkIcon } from "../../assets/CheckMarkIcon";
import makeApiRequest from "../../helpers/makeApiRequest";
import { navigationMap } from '../static';
import {getCookieValue, setCookie} from "../../helpers/cookieParser";
import {teethTypes} from "../static";
import {LayoutOverlay} from "../../components/LoadingOverlay";

const iconStyle = {
  position: "absolute",
  top: 9,
  right: 2,
  height: 12,
  opacity: 0.5,
};

const Step4 = () => {
  const { t } = useTranslation();
  const id = getCookieValue('id');
  const navigate = useNavigate();
  const [teethType, setTeethType] = useState(getCookieValue('teeth_type'));
  const [loading, setLoading] = useState(false);

  const onSubmit = async (value) => {
    setTeethType(value);
    setLoading(true);
    const response = await makeApiRequest(`customers/${id}/`, "PATCH", {
      teeth_type: String(value),
    });
    setLoading(false);
    if (!response.error) {
      setCookie('teeth_type', String(value));
      setCookie('status', response.status);
      const route = navigationMap[response.status];
      navigate(route || "/step5");
      setCookie('step', '5');
    }
  };
  
  const setSrc = (value) => {
    switch (value) {
      case 'Crowded':
        return crowded;
      case 'Spaced':
        return spaced;
      case 'Crooked':
        return crooked;
      default:
        return stickingOut;
    }
  }
  
  
  return (
    <div>
      <p className={styles.surveyHeading}>
        <Trans t={t}>How would you describe your teeth?</Trans>
      </p>
      <LayoutOverlay isActive={loading}>
      <div className={styles.teethTypeWrapper}>
        {teethTypes.map(type => (
            <div key={type.key} className={styles.teethTypeItem} onClick={() => onSubmit(type.key)}>
              <div
                  className={
                    type.key === Number(teethType)
                        ? `${styles.teethTypeItemSelectionMark} ${styles.selectionMarkIsSelected}`
                        : `${styles.teethTypeItemSelectionMark}`
                  }
              >
                <CheckMarkIcon propsStyle={iconStyle} />
              </div>
              <div className={styles.teethTypeImageContainer}>
                <img
                    className={styles.teethTypeImage}
                    src={setSrc(type.value)}
                    alt={type.value}
                />
              </div>
              <div
                  className={
                    type.key === Number(teethType)
                        ? `${styles.teethTypeItemContent} ${styles.teethTypeItemContentIsSelected}`
                        : `${styles.teethTypeItemContent}`
                  }
              >
                {type.value}
              </div>
            </div> 
        ))}
      </div>
      </LayoutOverlay>
      </div>
  );
};

export default Step4;
