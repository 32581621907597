import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';

import styles from "./styles.module.css";
import Button from "../../components/Button";
import makeApiRequest from "../../helpers/makeApiRequest";
import {navigationMap, teethConditions} from '../static';
import {getCookieValue, setCookie} from "../../helpers/cookieParser";
import {LayoutOverlay} from "../../components/LoadingOverlay";

const Step6 = () => {
  const { t } = useTranslation();
  const teethConditionsCookie = getCookieValue('teeth_conditions');
  
  const [loading, setLoading] = useState(false);
  const [checkList, setCheckList] = useState(teethConditionsCookie?.split(',') || []);
  const navigate = useNavigate();

  const onSubmit = async () => {
    const data = checkList.includes('4') ? null : checkList;

    const id = getCookieValue('id');
    setLoading(true);
    const response = await makeApiRequest(`customers/${id}/`, "PATCH", {
      teeth_conditions: data,
    });

    setLoading(false);
    if (!response.error) {
      const route = navigationMap[response.status];
      navigate(route || "/step7");
      setCookie('status', response.status);
      setCookie('step', '7');
    }
  };

  const selectTeethType = (index) => {
    if(index === '4') {
      setCheckList(['4']);
      setCookie('teeth_conditions', ['4'].join(','));
      return;
    }

    if (checkList.includes(index)) {
      const data = checkList.filter(id => id !== index);
      setCheckList(data);
      setCookie('teeth_conditions', data.join(','));
    } else {
      const data = [...checkList, index].filter(id => id !== '4');
      setCheckList(data);
      setCookie('teeth_conditions', data.join(','));
    }
  };

  return (
    <div>
      <p className={styles.surveyHeading}>
        <Trans t={t}>Do any of the following conditions applied?</Trans>
      </p>
      <LayoutOverlay isActive={loading}>
      <div className={styles.answersWrapper}>
        {teethConditions.map((condition, index) => (
            <button
                disabled={loading}
                key={index}
                className={
                  checkList.includes(String(index))
                      ? `${styles.btnAnswer} ${styles.btnAnswerSelected}`
                      : `${styles.btnAnswer}`
                }
                onClick={() => selectTeethType(String(index))}
            >
              {t(condition)}
            </button>
        ))}
      </div>
      </LayoutOverlay>
      <Button disabled={loading} onClick={onSubmit} />
    </div>
  );
};

export default Step6;
