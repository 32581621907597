import { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import styles from "./styles.module.css";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "../../assets/UploadIcon";
import { InfoBox } from './InfoBox';

function sizeValidator(file) {
  if (file) {
    if (file.size > 10000000) {
      return {
        code: "file-is-too-big",
        message: "File is too big",
      };
    }
  }

  return null;
}

const Step10 = ({ keyName, imageSrc, headerText, setFile }) => {
  const { t } = useTranslation();
  const [fileUploading, setFileUploading] = useState(false);
  const [fileProcessing, setFileProcessing] = useState(false);

  const onDropAccepted = (files) => {
      setFile(files[0]);
  };

  const onFileDialogOpen = () => {
    setFileUploading(false);
    setFileProcessing(false);
  };

  const onDropRejected = (fileRejections) => {
    const file = fileRejections[0].file;
    if (file) {
      if (sizeValidator(file)) {
        // setFileRejections(fileRejections);
      } else {
        onDropAccepted([file]);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive, } = useDropzone({
    accept: ['image/heic', 'image/jpg', 'image/jpeg', 'image/png'],
    maxFiles: 1,
    multiple: false,
    validator: sizeValidator,
    onFileDialogOpen,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <>
      <p className={styles.surveyHeading}>
        <Trans t={t}>{headerText}</Trans>
      </p>
      <img
          src={imageSrc}
          alt={keyName}
          className={styles.wrapper}
      />
      <a
        href="https://api.whatsapp.com/send/?phone=971505395868"
        rel="noreferrer"
        target="_blank"
        className={styles.link}
      >
        {t("Need help?")}
      </a>
      <div className={styles.dropzoneWrapper}>
        <div className={styles.dropzoneContainer} {...getRootProps()}>
          <input {...getInputProps()} />
          <UploadIcon className={styles.uploadIcon} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p className={styles.dropZoneText}>
              {t("Drag and drop to upload")}
              <span className={styles.dropZoneTextSpan}>
                {t("or")} <a href="#!">{t("browse")}</a> {t("to choose a file")}
              </span>
            </p>
          )}
        </div>
        <p className={styles.dropzoneHelper}>
          Maximum number of files is 1. Maximum upload size per file is 10 MB.
        </p>
        {fileProcessing && <InfoBox message='Processing file...'/>}
        {fileUploading && <InfoBox message='Uploading file...'/>}
      </div>
    </>
  );
};

export default Step10;

export const ePhotoKeys = {
  front_photo: 'front_photo',
  lower_jaw_photo: 'lower_jaw_photo',
  upper_jaw_photo: 'upper_jaw_photo',
};
