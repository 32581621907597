import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Step10, {ePhotoKeys} from "./index";
import {AcceptedFileItem} from "./AcceptedFileItem";
import {getCookieValue, setCookie} from "../../helpers/cookieParser";
import Button from "../../components/Button";
import styles from "./styles.module.css";
import makeApiRequest from "../../helpers/makeApiRequest";
import {LayoutOverlay} from "../../components/LoadingOverlay";
import {setFileName} from "../../helpers/imageFormat";

const LowerJawPhotoStep = () => {
    const [file, setFile] = useState(null);
    const [responseError, setResponseError] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const lowPhotoURL = getCookieValue('low_photo_url');
    const lowPhotoSize = getCookieValue('low_photo_size');
    const lowPhotoName = getCookieValue('low_photo_name');
    const id = getCookieValue('id');

    useEffect(() => {
        if(file) {
            setResponseError('');
            setError('');
            setCookie('low_photo_name', file.name);
            setCookie('low_photo_size', file.size);
        }
    }, [file])

    const onSubmit = async () => {
        if(!file) {
            setError('Please, upload new photo');
            return;
        }
        setError('');
        setLoading(true);
        const formData = new FormData();

        formData.append(ePhotoKeys.lower_jaw_photo, file || lowPhotoURL, lowPhotoName);

        const response = await makeApiRequest(
            `customers/${id}/upload_photo/`,
            "POST",
            formData,
            true
        );

        setLoading(false);

        if (response?.error !== undefined) {
            setResponseError(response.error.lower_jaw_photo);
        } else {
            setCookie('low_photo_url', response.lower_jaw_photo_url);
            setCookie('low_photo_name', file.name);
            setCookie('low_photo_size', file.size);
            navigate('/step10-upper_jaw_photo');
        }
    };

    return (
        <div className={styles.wrapper}>
            <Step10 imageSrc='https://static.surveysparrow.com/application/production/1628266032376__2fe0cebc4cc52f396d2d4a0d3bf6a045adf29c2d4e3f7d7bfebd794af19a__Lower Photo.gif'
                    headerText='Open your mouth wide and take a photo of your lower teeth'
                    setFile={setFile}
            />
            {responseError && <div className={styles['preview-image-box']}>
                {responseError.map((error) => (
                    <span key={error} style={{ color: "rgb(229, 85, 79)" }}>
                        {error}
                     </span>
                ))}
            </div>}
            {error && <div className={styles['preview-image-box']}>
                    <span key={error} style={{ color: "rgb(229, 85, 79)" }}>
                        {error}
                     </span>
            </div>}
            <LayoutOverlay isActive={loading}>
            {(file || lowPhotoURL)  &&
                <AcceptedFileItem
                    url={file ? URL.createObjectURL(file) : lowPhotoURL}
                    name={file ? setFileName(file.name) : setFileName(lowPhotoName)}
                    size={file ? file.size : lowPhotoSize}
                />
            }
            <Button onClick={onSubmit} />
            </LayoutOverlay>
        </div>
    )
}

export default LowerJawPhotoStep;
