import styles from "./styles.module.css";

const overlay = {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#00000000',
    zIndex: 2,
    cursor: 'pointer',
    opacity: 0.2,
}

const spinner = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: '22px',
    color: 'black',
    transform: 'translate(-50%,-50%)',
}

export const LayoutOverlay = ({isActive, children}) => {
    return (
        <div style={isActive ? overlay : null}>
            {children}
            <div style={spinner}>{isActive ? 
                <>
                    <div className={styles.loader} />
                    <p style={{marginTop: 12, fontSize: 20}}>Loading ...</p>
                </> : 
                null}</div>
        </div>
    )
}
