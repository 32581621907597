import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import styles from "./styles.module.css";
import makeApiRequest from "../../helpers/makeApiRequest";
import { navigationMap } from '../static';
import {getCookieValue, setCookie} from "../../helpers/cookieParser";
import {LayoutOverlay} from "../../components/LoadingOverlay";

const Step3 = () => {
  const { t } = useTranslation();
  const id = getCookieValue('id');
  const firstName = getCookieValue('first_name');
  const [isAdult, setIsAdult] = useState(getCookieValue('is_adult'));
  const [responseError, setResponseError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (isAdult) => {
    setIsAdult(isAdult);
    setLoading(true);
    const response = await makeApiRequest(`customers/${id}/`, "PATCH", {
      is_adult: isAdult,
    });

    setLoading(false);
    if (response.error !== undefined) {
      setResponseError(response.error);
    } else {
      setCookie('status', response.status);
      setCookie('is_adult', String(isAdult));
      const route = navigationMap[response.status];
      
      !isAdult
        ? navigate("/adult-only", { replace: true })
        : navigate(route || "/step4");
      setCookie('step', '4');
    }
  };

  return (
      <LayoutOverlay isActive={loading}>
    <div className={styles.wrapper}>
      <p className={styles.surveyHeading}>
        {firstName}, {t("are you above 18?")}
      </p>
      <div className={styles.answersWrapper}>
        <button
            disabled={loading}
          className={
            isAdult === 'true'
              ? `${styles.btnAnswer} ${styles.btnAnswerSelected}`
              : `${styles.btnAnswer}`
          }
          onClick={() => onSubmit(true)}
        >
          {t("Yes, 18 years or above")}
        </button>
        <button
            disabled={loading}
          className={
            isAdult === 'false'
              ? `${styles.btnAnswer} ${styles.btnAnswerSelected}`
              : `${styles.btnAnswer}`
          }
          onClick={() => onSubmit(false)}
        >
          {t("No, below 18 years")}
        </button>
        {responseError && (
          <p
            style={{
              color: "rgb(229, 85, 79)",
              position: "absolute",
              left: 0,
              bottom: 0,
              transform: 'translateY(100%)',
            }}
          >
            {t(responseError)}
          </p>
        )}
      </div>
    </div>
      </LayoutOverlay>
  );
};

export default Step3;
