import {memo} from "react";
import styles from "./styles.module.css";
import logo from "../../assets/logo.png"
import LanguageButton from "../LanguageButton";
import {getCookieValue, setCookie} from "../../helpers/cookieParser";
import {useLocation} from "react-router-dom";

const Header = memo(({setLang}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const country = getCookieValue('url_country') || queryParams.get('url_country');

  const isLanguageButtonAllowed = country !== 'tr' && (location.pathname === '/' || location.pathname === '/step2');

  setCookie('url_country', country);

  return (
    <header className={(location.pathname === '/' || location.pathname === '/step2') ? styles.header : styles.centerHeader}>
      <div style={{width: '8%'}} />
      <img src={logo} alt="Smileneo logo" className={styles.headerLogo}/>
      {isLanguageButtonAllowed && <LanguageButton setLang={setLang} /> }
    </header>
  );
});

export default Header;
