import {memo} from "react";
import styles from "../../steps/Step2/styles.module.css";
import Button from "../../components/Button";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const AdultOnly = memo(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
            <div className={styles.wrapper}>
                <p style={{marginBottom: 20}}>{t('Sorry, you need to be above 18 to proceed')}</p>
                <Button title={t('back')} onClick={() => navigate('/step3')} />
            </div>
        )
    }
);

export default AdultOnly;
