import {useState} from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import styles from "./styles.module.css";
import makeApiRequest from "../../helpers/makeApiRequest";

import locationsList from "./../../locactions.json";
import { navigationMap } from '../static';
import {getCookieValue, setCookie} from "../../helpers/cookieParser";
import {LayoutOverlay} from "../../components/LoadingOverlay";

const Step7 = () => {
  const { t } = useTranslation();
  const [location, setLocation] = useState(getCookieValue('location'));
  const navigate = useNavigate();
  const country = getCookieValue('url_country');
  const [responseError, setResponseError] = useState("");
  const [loading, setLoading] = useState(false);
  
  const onSubmit = async (value) => {
      setLocation(value);
    const id = getCookieValue('id');
    setLoading(true);
    const response = await makeApiRequest(`customers/${id}/`, "PATCH", {
      location: value,
    });

    setLoading(false);
    if(response.error) {
      setResponseError(response.error)
    } else {
      setCookie('status', response.status);
      setCookie('location', value);
      const route = navigationMap[response.status];
      navigate(route || "/step8");
      setCookie('step', '8');
    }
  };

  return (
    <div>
      <p className={styles.surveyHeading}>
        <Trans t={t}>
          What's your preferred Location for the Doctor Consultation?
        </Trans>
      </p>
      <LayoutOverlay isActive={loading}>
      <div className={styles.answersWrapper}>
        {locationsList[country].map((item, index) => {
          return (
            <button 
                disabled={loading}
              key={index}
              id={index}
              data-location={t(item)}
              className={
                location === t(item)
                  ? `${styles.btnAnswer} ${styles.btnAnswerSelected}`
                  : `${styles.btnAnswer}`
              }
              onClick={() => onSubmit(t(item))}
            >
              {t(item)}
            </button>
          );
        })}
      </div>
      </LayoutOverlay>
      {responseError && (
          <p
              style={{
                color: "rgb(229, 85, 79)",
                position: "absolute",
                left: 0,
                bottom: 0,
                transform: 'translateY(100%)',
              }}
          >
            {t(responseError)}
          </p>
      )}
    </div>
  );
};

export default Step7;
