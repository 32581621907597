export const resources = {
  en: {
    translation: {
      "Let's start with your Name":
        "Let's start with your <strong>Name</strong>",
      "Please enter your response": "Please enter your response",
      Next: "Next",
      "are you above 18?": "are you above 18?",
      "Yes, 18 years or above": "Yes, 18 years or above",
      "No, below 18 years": "No, below 18 years",
      "please share your Phone Number":
        "please share your <strong>Phone Number</strong>",
      "The phone number isn't valid": "The phone number isn't valid",
      "We're providing status updates via WhatsApp!":
        "We're providing status updates via WhatsApp!",
      "Phone Number": "Phone Number",
      "How would you describe your teeth?":
        "How would you <strong>describe</strong> your teeth?",
      Crowded: "Crowded",
      Spaced: "Spaced",
      Crooked: "Crooked",
      "Sticking out": "Sticking out",
      "Did you wear braces or aligners before?":
        "Did you wear <strong>braces or aligners</strong> before?",
      Yes: "Yes",
      No: "No",
      "Do any of the following conditions applied?":
        "Do any of the following <strong>conditions</strong> applied?",
      "I have unfilled cavities": "I have unfilled cavities",
      "I have gum disease": "I have gum disease",
      "I have osteoporosis": "I have osteoporosis",
      "I have fixed retainers": "I have fixed retainers",
      "None of the above": "None of the above",
      "What's your preferred Location for the Doctor Consultation?":
        "What's your preferred <strong>Location</strong> for the Doctor Consultation?",
      Dubai: "Dubai",
      "Abu Dhabi": "Abu Dhabi",
      "Al Ain": "Al Ain",
      Fujairah: "Fujairah",
      Sharjah: "Sharjah",
      Ajman: "Ajman",
      "Ras Al Khaimah": "Ras Al Khaimah",
      "Umm Al-Quwain": "Umm Al-Quwain",
      "To which Email should we send the Doctor Consultation invite":
        "To which <strong>Email</strong> should we send the Doctor Consultation invite",
      "Please enter an email": "Please enter an email",
      "Please enter a valid email": "Please enter a valid email",
      Submit: "Submit",
      "Press ENTER key to submit": "Press ENTER key to submit",
      "You're almost there": "You're almost there",
      "Lastly we need 3 photos to fully assess your smile.":
        "Lastly we need 3 photos to fully assess your smile.",
      "Take 3 photos": "Take 3 photos",
      "Take a photo of your closed bite.":
        "Take a photo of your <strong>closed bite.</strong>",
      "Need help?": "Need help?",
      "Drag and drop to upload": "Drag and drop to upload",
      or: "or",
      broswe: "broswe",
      "to choose a file": "to choose a file",
      next: "next",

      Riyadh: "Riyadh",
      Jeddah: "Jeddah",
      Mecca: "Mecca",
      Medina: "Medina",
      "Al Qassim": "Al Qassim",
      Dammam: "Dammam",
      "Al Qatif": "Al Qatif",
      "Al Khobar": "Al Khobar",
      "Khamis Mushait": "Khamis Mushait",

      Istanbul: "Istanbul",
      Izmir: "Izmir",
      Bursa: "Bursa",
      Ankara: "Ankara",

      Lahore: "Lahore",
      Karachi: "Karachi",
      Islamabad: "Islamabad",

      Doha: "Doha",

      Other: "Other",
      "Thank you": "Thank you",
      "We'll come back to you via email within 24 hours": "We'll come back to you via email within 24 hours",
      "Open your mouth wide and take a photo of your lower teeth": "Open your mouth wide and take a photo of your <strong>lower teeth</strong>",
      "Open your mouth wide and take a photo of your upper teeth.": "Open your mouth wide and take a photo of your <strong>upper teeth.</strong>",
      "take 3 photos": "take 3 photos",
      "Follow Us": "Follow Us",
    },
  },
  ar: {
    translation: {
      "Sorry, you need to be above 18 to proceed": "عذرًا ، يجب أن يكون عمرك أكبر من 18 عامًا للمتابعة",
      "Let's start with your Name": "لنبدأ باسمك:",
      "Please enter your response": "الرجاء إدخال ردك",
      Next: "التالي",
      "are you above 18?": "هل أنت فوق 18 عامًا؟",
      "Yes, 18 years or above": "نعم ، 18 سنة أو أكثر",
      "No, below 18 years": "لا ، أقل من 18 سنة",
      "please share your Phone Number": "رقم هاتفك:",
      "The phone number isn't valid": "رقم الهاتف غير صالح",
      "We're providing status updates via WhatsApp!":
        "سيتم ارسال اي تحديثات عن الحالة عبر واتساب إلى هذا الرقم. يرجى كتابة الرقم باللغة الإنجليزية",
      "Phone Number": "رقم الهاتف",
      "How would you describe your teeth?": "كيف تصف أسنانك؟",
      Crowded: "أعوج",
      Spaced: "متباعدة",
      Crooked: "مزدحمة",
      "Sticking out": "تخرج",
      "Did you wear braces or aligners before?":
        "هل ارتديت تقويم الأسنان أو مصففات الأسنان من قبل؟",
      Yes: "لا",
      No: "نعم",
      "Do any of the following conditions applied?":
        "هل تنطبق أي من الشروط التالية؟",
      "I have unfilled cavities": "لدي تجاويف غير مملوءة",
      "I have gum disease": "أنا أعاني من مرض اللثة",
      "I have osteoporosis": "أنا أعاني من هشاشة عظام الفك",
      "I have fixed retainers": "لدي المُثبّتات المعدنية",
      "None of the above": "لا شيء مما سبق",
      "What's your preferred Location for the Doctor Consultation?":
        "في أي مدينة تفضل حجز موعدك لاستشارة الطبيب؟",
      Dubai: "رأس الخيمة",
      "Abu Dhabi": "الشارقة",
      Fujairah: "دبي",
      "Al Ain": "العين",
      Sharjah: "أم القيوين",
      Ajman: "عجمان",
      "Ras Al Khaimah": "الفجيرة",
      "Umm Al-Quwain": "أبو ظبي",
      "To which Email should we send the Doctor Consultation invite":
        "عنوان بريدك الإلكتروني لتلقي دعوة استشارة الطبيب.",
      "Please enter an email": "الرجاء إدخال بريد إلكتروني",
      "Please enter a valid email": "يرجى إدخال البريد الإلكتروني الصحيح",
      Submit: "التالي",
      back: "خلف",
      "Press ENTER key to submit": "اضغط على مفتاح ENTER للإرسال",
      "You're almost there": "انت على وشك الانتهاء",
      "Lastly we need 3 photos to fully assess your smile.":
        "قم بتصوير أسنانك وهي متطابقة",
      "Take 3 photos": "خذ ثلاث صور",
      "Take a photo of your closed bite.": "قم بتصوير عضتك",
      "Need help?": "تحتاج مساعدة؟",
      "Drag and drop to upload": "سحب وإسقاط للتحميل",
      or: "أو",
      browse: "تصفح",
      "to choose a file": "لاختيار ملف",
      next: "التالي",

      Riyadh: "الرياض",
      Jeddah: "جدة",
      Mecca: "مكة المكرمة",
      Medina: "المدينة المنورة",
      "Al Qassim": "القصيم",
      Dammam: "الدمام",
      "Al Qatif": "القطيف",
      "Al Khobar": "الخبر",
      "Khamis Mushait": "خميس مشيط",

      Istanbul: "Istanbul",
      Izmir: "Izmir",
      Bursa: "Bursa",
      Ankara: "Ankara",

      Lahore: "لاهور",
      Karachi: "كراشي",
      Islamabad: "إسلمباد",

      Doha: "الدوحة",

      Other: "اخرى",
      "Thank you": "شكرا لك!",
      "We'll come back to you via email within 24 hours": "سنعود إليك عبر البريد الإلكتروني في غضون 24 ساعة.",
      "Open your mouth wide and take a photo of your lower teeth": "افتح فمك بقدر المستطاع والتقط صورة لأسنانك السفلية.",
      "Open your mouth wide and take a photo of your upper teeth.": " افتح فمك بقدر المستطاع والتقط صورة لأسنانك العلوية.",
      "take 3 photos": "خذ ثلاث صور",
      "Follow Us": "تابعنا",
    },
  },
};
