import React from "react";

export const ThumbUp = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 42 42"
      style={props.style}
    >
      <g fill="currentColor">
        <path d="M9.841 42h-6.53a3.287 3.287 0 01-3.283-3.284V19.034A3.287 3.287 0 013.31 15.75h6.531a3.289 3.289 0 013.284 3.284v19.682A3.288 3.288 0 019.841 42zm-6.53-23.625a.659.659 0 00-.658.659v19.682a.66.66 0 00.657.659h6.531a.66.66 0 00.659-.659V19.034a.66.66 0 00-.659-.659h-6.53z"></path>
        <path d="M33.898 42H17.99c-.81 0-1.62-.192-2.345-.554l-4.42-2.21a1.313 1.313 0 011.174-2.348l4.42 2.21a2.63 2.63 0 001.171.277h15.908c.479 0 .925-.259 1.164-.676l1.482-2.593a21.526 21.526 0 002.831-10.662v-4.441a2.63 2.63 0 00-2.628-2.628h-9.879a2.618 2.618 0 01-2.432-3.592 25.25 25.25 0 001.814-9.421V3.84c0-.669-.545-1.215-1.215-1.215h-.817c-.54 0-1.02.362-1.168.88l-.514 1.8A29.96 29.96 0 0117.1 15.833a6.735 6.735 0 01-5.288 2.542H7.874a1.313 1.313 0 010-2.625h3.938a4.129 4.129 0 003.239-1.558 27.35 27.35 0 004.96-9.609l.514-1.8A3.858 3.858 0 0124.218 0h.817a3.844 3.844 0 013.84 3.84v1.522c0 3.579-.675 7.077-2.002 10.396l9.874-.008A5.258 5.258 0 0142 21.003v4.441a24.15 24.15 0 01-3.178 11.965l-1.481 2.593A3.975 3.975 0 0133.898 42z"></path>
        <circle cx="6.563" cy="35.438" r="1.313"></circle>
      </g>
    </svg>
  );
};
