import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';

import styles from "./styles.module.css";
import { ThumbDown } from "../../assets/ThumbDown";
import { ThumbUp } from "../../assets/ThumbUp";
import makeApiRequest from "../../helpers/makeApiRequest";
import { navigationMap } from '../static';
import {getCookieValue, setCookie} from "../../helpers/cookieParser";
import {LayoutOverlay} from "../../components/LoadingOverlay";

const Step6 = () => {
  const { t } = useTranslation();
  const [bracesBefore, setBracesBefore] = useState(getCookieValue('braces_before'));
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();

  const onSubmit = async (value) => {
    setBracesBefore(value);
    setLoading(true);
    const id = getCookieValue('id');
    const response = await makeApiRequest(`customers/${id}/`, "PATCH", {
      braces_before: value,
    });

    setLoading(false);
    if (!response.error) {
      setCookie('braces_before', value);
      setCookie('status', response.status);
      const route = navigationMap[response.status];
      navigate(route || "/step6");
      setCookie('step', '6');
    }
  };

  return (
    <div>
      <p className={styles.surveyHeading}>
        <Trans t={t}>Did you wear braces or aligners before?</Trans>
      </p>
      <LayoutOverlay isActive={loading}>
      <div className={styles.answersWrapper}>
        <div
          className={
            bracesBefore === 'true'
              ? `${styles.answerOption} ${styles.answerOptionSelected}`
              : `${styles.answerOption}`
          }
          onClick={() => onSubmit('true')}
        >
          <ThumbUp />
          <p className={styles.answerText}>{t("Yes")}</p>
        </div>
        <div
          className={
            bracesBefore === 'false'
              ? `${styles.answerOption} ${styles.answerOptionSelected}`
              : `${styles.answerOption}`
          }
          onClick={() => onSubmit('false')}
        >
          <ThumbDown />
          <p className={styles.answerText}>{t("No")}</p>
        </div>
      </div>
      </LayoutOverlay>
    </div>
  );
};

export default Step6;
