let baseUrl = "/api/v1";

const isDev = process.env.NODE_ENV === 'development';
if (isDev) {
  baseUrl = "https://smileneo.uaenorth.cloudapp.azure.com/api/v1";
}


const makeApiRequest = async (endpoint, method = "get", body, file = false) => {
  let data;
  try {
    const response = await fetch(`${baseUrl}/${endpoint}`, {
      method,
      ...(body && { body: file ? body : JSON.stringify(body) }),
      headers: file ? {} : {
        "Content-Type": "application/json",
      },
      credentials: 'include',
    });

    data = await response.json();

    if(response.status === 400 || response.status === 403) {
      data = { error: data }
    }
  } catch (error) {
    data = { error: "Something went wrong, try later" }
  }
  return data;
};

export default makeApiRequest;
