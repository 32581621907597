import React from "react";

export const UploadIcon = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="85"
      height="74"
      viewBox="0 0 85 74"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M58.723 52.091h9.054S84 49.987 84 34.155a17.847 17.847 0 0 0-5.515-12.904 18.068 18.068 0 0 0-13.179-4.995C60.391 5.887 49.026.152 37.704 2.328c-11.322 2.175-19.718 11.707-20.39 23.15a13.59 13.59 0 0 0-11.306 2.714A13.417 13.417 0 0 0 1 38.625C1 52.294 15.434 52.08 15.434 52.08h10.802M42.5 31.466v41.25"></path>
        <path d="M53.818 42.716L42.5 31.466l-11.318 11.25"></path>
      </g>
    </svg>
  );
};
