import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation, Trans } from 'react-i18next';
import styles from "./styles.module.css";
import Button from "../../components/Button";
import makeApiRequest from "../../helpers/makeApiRequest";
import { navigationMap } from '../static';
import {getCookieValue, setCookie} from "../../helpers/cookieParser";
import {LayoutOverlay} from "../../components/LoadingOverlay";

const Step2 = () => {
  const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState("");
  const navigate = useNavigate();
  const firstName = getCookieValue('first_name');
  const language = getCookieValue('url_lang');
  const phone = getCookieValue('phone_number');
  const phoneCountry = getCookieValue('url_country') || getCookieValue('phone_country');
  
  const onSubmit = async () => {
      setLoading(true);
      
    const response = await makeApiRequest("customers/create/", "POST", {
      first_name: getCookieValue('first_name'),
      phone_number: getCookieValue('phone_number'),
      country: getCookieValue('url_country'),
      language: getCookieValue('url_lang') || 'en',
    });

      setLoading(false);
    if (response.error !== undefined) {
      setResponseError("The phone number isn't valid");
    } else {
      const route = navigationMap[response.status];
      setCookie('id', response.id);
      setCookie('status', response.status);
      setCookie('phone_number', getCookieValue('phone_number'));
      setCookie('location', response.location);
      setCookie('teeth_conditions', response.teeth_conditions);
      setCookie('email', response.email);
      setCookie('is_adult', response.is_adult?.toString());
      setCookie('braces_before', response.braces_before?.toString());
      setCookie('teeth_type', response.teeth_type);
      setCookie('step', '3');
      
      setCookie('lower_photo_url', response.lower_jaw_photo_url);
      setCookie('upper_photo_url', response.upper_jaw_photo_url);
      setCookie('front_photo_url', response.front_photo_url);
      navigate(route || "/step3");
    }
  };
  
  return (
      <LayoutOverlay isActive={loading}>
          <div className={styles.wrapper}>
              <p className={styles.question}>
                  {firstName}, <Trans t={t}>please share your Phone Number</Trans>
              </p>
              <p className={styles.statusUpdate}>
                  {t("We're providing status updates via WhatsApp!")}
              </p>
              <div id={styles["phoneInputWrapper"]}>
                  <PhoneInput
                      style={{textAlign: 'right'}}
                      disabled={loading}
                      enableSearch
                      disableSearchIcon
                      containerClass={
                          language === 'ar'
                              ? `${styles.phoneInputContainer} ${styles.rtl}`
                              : 
                          `${styles.phoneInputContainer}`
                      }
                      inputClass={styles.phoneInput}
                      buttonClass={styles.phoneButton}
                      dropdownClass={styles.phoneDropdown}
                      country={phoneCountry}
                      value={phone}
                      onChange={(value, country, e, formattedValue) => {
                          setResponseError('');
                          setCookie('phone_number', formattedValue);
                          setCookie('phone_country', country.countryCode);
                      }}
                  />
                  {responseError && (
                      <p
                          style={{
                              color: "rgb(229, 85, 79)",
                              position: "absolute",
                              bottom: 0,
                              transform: 'translateY(100%)',
                          }}
                      >
                          {t(responseError)}
                      </p>
                  )}
              </div>
              <Button onClick={onSubmit} disabled={(phone && !phone.length) || loading} />
          </div>
      </LayoutOverlay>
  );
};

export default Step2;
