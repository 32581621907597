import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import {useSearchParams} from 'react-router-dom';
import styles from "./styles.module.css";
import {getCookieValue, setCookie} from '../../helpers/cookieParser';

export const LANGUAGES = [
  { value: "ar", label: "عربي", direction: "rtl" },
  { value: "en", label: "English", direction: "ltr" },
];

const LanguageButton = ({setLang}) => {
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams = Object.fromEntries([...searchParams]);
  const [selected, setSelected] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const url_lang = queryParams.get('url_lang');
  const url_country = queryParams.get('url_country');

  let language = getCookieValue('url_lang') || url_lang;
  const country = getCookieValue('url_country') || url_country || getCookieValue('phone_country');

  useEffect(() => {
    if (language === "ar") {
      setLang('ar');
      document.body.style = "direction:rtl";
    } else {
      setLang('en');
      document.body.style = "direction:ltr";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const changeLanguageHandler = (value) => {
    i18n.changeLanguage(value);
    
    setCookie('url_lang', value);

    if(url_lang) {
      const updatedParams = {
        ...currentParams,
        url_lang: value,
      };

      setSearchParams(updatedParams); 
    }
  };
  
  const optionToChange = LANGUAGES.find((l) => l.value !== language);

  return (
      <div className={styles.wrapper}>
          <ReactFlagsSelect
              className={styles.countryButton}
              countries={["AE", "QA", "BH", "SA"]}
              customLabels={{}}
              placeholder='Select'
              showSelectedLabel={false}
              showOptionLabel={false}
              // customLabels={{ AE: "AE", QA: "QA", BH: "BH", SA: "SA" }}
              selected={selected?.toUpperCase() || country?.toUpperCase()}
              onSelect={(code) => {
                setCookie('url_country', code.toLowerCase());
                setCookie('phone_country', code.toLowerCase());
                setSelected(code.toLowerCase())
              }}
          />
          <button className={styles.languageButton}
                  onClick={(event) => {
                      event.preventDefault();
                      changeLanguageHandler(optionToChange.value)
                  }} >
              {optionToChange.label}
          </button>
      </div>
  );
};

export default LanguageButton;
